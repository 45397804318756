import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import mainSlider1IMG from '../img/main-slider-1.png';
import mainSlider1XsIMG from '../img/main-slider-1-xs.png';
import category1IMG from '../img/category-1.png';
import category2IMG from '../img/category-2.png';
import category3IMG from '../img/category-3.png';
import category4IMG from '../img/category-4.png';
import category5IMG from '../img/category-5.png';
import category6IMG from '../img/category-6.png';
import category7IMG from '../img/category-7.png';
import category8IMG from '../img/category-8.png';
import category9IMG from '../img/category-9.png';
import category10IMG from '../img/category-10.png';
import category11IMG from '../img/category-11.png';
import category12IMG from '../img/category-12.png';
import category13IMG from '../img/category-13.png';
import category14IMG from '../img/category-14.png';
import category15IMG from '../img/category-15.png';
import category16IMG from '../img/category-16.png';
import item1IMG from '../img/item-1.png';
import item2IMG from '../img/item-2.png';
import item3IMG from '../img/item-3.png';
import item4IMG from '../img/item-4.png';
import item5IMG from '../img/item-5.png';
import itemManufacturer1IMG from '../img/item-manufacturer-1.png';
import itemManufacturer2IMG from '../img/item-manufacturer-2.png';
import itemManufacturer3IMG from '../img/item-manufacturer-3.png';
import itemManufacturer4IMG from '../img/item-manufacturer-4.png';
import itemManufacturer5IMG from '../img/item-manufacturer-5.png';
import brandItem1IMG from '../img/brand-item-1.png';
import brandItem2IMG from '../img/brand-item-2.png';
import brandItem3IMG from '../img/brand-item-3.png';
import brandItem4IMG from '../img/brand-item-4.png';
import brandItem5IMG from '../img/brand-item-5.png';
import brandItem6IMG from '../img/brand-item-6.png';
import brandItem7IMG from '../img/brand-item-7.png';
import brandItem8IMG from '../img/brand-item-8.png';
import brandItem9IMG from '../img/brand-item-9.png';
import manufacturer1IMG from '../img/manufacturer-1.png';
import manufacturer2IMG from '../img/manufacturer-2.png';
import manufacturer3IMG from '../img/manufacturer-3.png';
import news1IMG from '../img/news-1.png';
import news2IMG from '../img/news-2.png';
import news3IMG from '../img/news-3.png';
import news4IMG from '../img/news-4.png';
import news5IMG from '../img/news-5.png';
import brand1IMG from '../img/brand-1.png';
import brand2IMG from '../img/brand-2.png';
import brand3IMG from '../img/brand-3.png';
import brand4IMG from '../img/brand-4.png';
import brand5IMG from '../img/brand-5.png';
import brand6IMG from '../img/brand-6.png';